import React from 'react';
import SearchBox from "../reusables/SearchBox";
import { FilterSection } from 'components/reusables/AppStyles';
const ClientsActions = ({search, onSearchChange, onSearchPressed, handleNewClient}) => {
	return (
		<>
      <FilterSection>
				<div>
					<SearchBox value={search} onChange={onSearchChange} onPress={onSearchPressed}>
					</SearchBox>
				</div>
			</FilterSection>

		</>
	);
};
export default ClientsActions;
