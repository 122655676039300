import React, { useState, useEffect, useCallback } from 'react';
import Drawer from '@material-ui/core/Drawer';
import AddBoxOutlined from '@material-ui/icons/PlaylistAddOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from '@material-ui/core/CardHeader';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import { useDropzone } from 'react-dropzone';
import useWindowDimensions from 'components/reusables/WindowSize';
import {
  DrawerContent,
  DrawerSecondaryCard,
  DrawerCard,
  FormButton,
  DrawerCardContent,
  DrawerCardActions,
  DrawerCloseButton,
  FormTextField,
  FormMenuItem
} from 'components/reusables/Contents';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  GENERATE_PRESIGNED_URL,
  CREATE_TAG_OR_TYPE,
  EDIT_FILE
} from 'graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
//
const drawerWidth = 400;
const initialFormState = {
  id: '',
  filenames: [],
  description: '',
  accountName: '',
  filetag_id: '',
  filetag_name: '',
  filetype_id: '',
  filetype_name: '',
  status: '',
  archived: false,
  accessLevel: 'client'
};
const initialCreateState = {
  name: '',
  created_type: null
};
const useStyles = makeStyles({
  drawerPaper: {
    width: drawerWidth
  },
  addIcon: {
    margin: '6px',
    cursor: 'pointer',
    color: '#757575'
  },
  fileFormContainer: {
    width: '400px'
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  createContainer: {
    width: '300px'
  },
  createForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  uploadBox: {
    width: '100%',
    maxHeight: '250px',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px'
  },
  uploadSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100px'
  },
  fileName: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '5px 10px 5px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e8e8e8'
    }
  },
  fileCount: {
    width: '100%',
    textAlign: 'start',
    margin: '5px 0'
  },
  uploadIcon: {
    fontSize: '2.5rem',
    margin: '10px 0'
  },
  filesContainer: {
    overflowY: 'auto',
    maxHeight: '150px',
    width: '100%'
  },
  currentFile: {
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    fontStyle: 'italic'
  }
});
const UploadDrawer = ({
  openDrawer,
  setOpenDrawer,
  drawer,
  getFiles,
  getTags,
  getTypes,
  selectedFile,
  setSelectedFile,
  handleDownload,
  data,
  inputVariables
}) => {
  const { width, height } = useWindowDimensions();
  const { companyId } = useParams();
  const classes = useStyles();
  const [fileForm, setFileForm] = useState(initialFormState);
  const [createForm, setCreateForm] = useState(initialCreateState);
  const [openCreateForm, setOpenCreateForm] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [currentFile, setCurrentFile] = useState({});

  useEffect(() => {
    createForm.created_type
      ? setOpenCreateForm(true)
      : setOpenCreateForm(false);
  }, [createForm]);
  const [createTagOrType, {}] = useMutation(CREATE_TAG_OR_TYPE, {
    variables: {
      name: createForm.name,
      created_type: createForm.created_type
    },
    onCompleted: ({ createTagOrType }) => {
      const { created_type, name, id } = createTagOrType;
      created_type === 'filetype' ? getTypes() : getTags();
      created_type === 'filetype'
        ? setFileForm({ ...fileForm, filetype_id: id, filetype_name: name })
        : setFileForm({ ...fileForm, filetag_id: id, filetag_name: name });
      setCreateForm(initialCreateState);
      setOpenCreateForm(false);
    }
  });
  const [editFile, {}] = useMutation(EDIT_FILE, {
    onCompleted: ({ editFile }) => {
      if (editFile.success) {
        console.log('#### edit files success');
        setOpenDrawer(false);
        getFiles({
          variables: inputVariables
        });
      } else {
        setUploadStatus('Failed');
      }
    }
  });
  const handleUpload = async (resp, file) => {
    const response = await fetch(
      new Request(resp.url, {
        method: 'PUT',
        body: file,
        headers: new Headers({
          'Content-Type': `${file.type}`
        })
      })
    );
    if (response.status !== 200) {
      setUploadStatus('Failed');
      editFile({
        variables: {
          file_id: resp.obj_id,
          uploaded: false,
          upload_input: null
        }
      });
    }
  };
  const [generatePresignedUrl, {}] = useMutation(GENERATE_PRESIGNED_URL);

  const handleMultipleUploads = async () => {
    let count = 1;
    for (const file of files) {
      setCurrentFile(file);
      const {
        data: { generatePresignedUrl: dataObj }
      } = await generatePresignedUrl({
        variables: {
          company_id: companyId,
          upload_input: {
            filename: file.name,
            description: fileForm.description,
            account_name: fileForm.accountName,
            filetype_id: fileForm.filetype_id,
            filetag_id: fileForm.filetag_id,
            archived: fileForm.archived,
            access_level: fileForm.accessLevel,
            source: 'Upload'
          }
        }
      });
      const _data = await handleUpload(dataObj, file);
      if (count === fileCount) {
        setUploadStatus('Success');
      }
      count += 1;
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setUploadStatus('uploading');
    if (drawer === 'upload') {
      handleMultipleUploads();
    } else {
      editFile({
        variables: {
          file_id: fileForm.id,
          uploaded: null,
          upload_input: {
            filename: fileForm.filename,
            description: fileForm.description,
            filetype_id: fileForm.filetype_id,
            filetag_id: fileForm.filetag_id,
            archived: fileForm.archived,
            access_level: fileForm.accessLevel,
            account_name: fileForm.accountName
          }
        }
      });
    }
  };
  const handleCreateSubmit = e => {
    e.preventDefault();
    createTagOrType();
  };
  const handleFormChange = e => {
    const { name, value, checked } = e.target;
    if (name === 'type') {
      data.types.forEach(type => {
        if (type.name === value) {
          return setFileForm({
            ...fileForm,
            filetype_name: type.name,
            filetype_id: type.id
          });
        }
      });
    } else if (name === 'tag') {
      data.tags.forEach(tag => {
        if (tag.name === value) {
          return setFileForm({
            ...fileForm,
            filetag_name: tag.name,
            filetag_id: tag.id
          });
        }
      });
    } else if (name === 'archived') {
      setFileForm({ ...fileForm, archived: checked });
    } else {
      setFileForm({ ...fileForm, [name]: value });
    }
  };
  const handleCreateChange = e => {
    const { value } = e.target;
    setCreateForm({ ...createForm, name: value });
  };
  const handleClose = () => {
    setOpenDrawer(false);
    setSelectedFile(null);
  };
  const handleCreateCancel = () => {
    setOpenCreateForm(false);
  };
  const handleAddIcon = type => {
    setCreateForm({ ...createForm, created_type: type });
  };
  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });
  const removeFile = path => {
    const fileList = files.filter(file => {
      if (file.path !== path) {
        return file;
      }
    });
    setFiles(fileList);
  };

  useEffect(() => {
    const filenames = files.map(file => file.name);
    setFileForm({ ...fileForm, filenames });
    setFileCount(files.length);
  }, [files]);

  useEffect(() => {
    if (uploadStatus === 'Success') {
      setFiles([]);
      setFileCount(0);
      setUploadStatus('');
      setOpenDrawer(false);
      getFiles({
        variables: inputVariables
      });
    }
  }, [uploadStatus]);
  useEffect(() => {
    if (selectedFile) {
      setFileForm({
        ...fileForm,
        id: selectedFile.id,
        filename: selectedFile.filename,
        description: selectedFile.description,
        accountName: selectedFile.account_name ? selectedFile.account_name : '',
        filetag_id: selectedFile.filetag ? selectedFile.filetag.id : '',
        filetag_name: selectedFile.filetag ? selectedFile.filetag.name : '',
        filetype_id: selectedFile.filetype ? selectedFile.filetype.id : '',
        filetype_name: selectedFile.filetype ? selectedFile.filetype.name : '',
        archived: selectedFile.archived,
        accessLevel: selectedFile.access_level
      });
    }
  }, [selectedFile]);

  const CreateForm = () => (
    <div>
      <CardHeader
        title={
          createForm.created_type === 'filetype'
            ? 'Create File Type'
            : 'Create File Tag'
        }
      />
      <DrawerCardContent>
        <form onSubmit={handleCreateSubmit}>
          <FormTextField
            error
            margin='dense'
            name='name'
            label='Name'
            placeholder={
              createForm.created_type === 'filetype'
                ? 'Enter Type Name'
                : 'Enter Tag Name'
            }
            value={createForm.name}
            onChange={handleCreateChange}
          />
          <DrawerCardActions>
            <FormButton color='primary' type='submit' size={'medium'}>
              Create
            </FormButton>
            <FormButton onClick={handleCreateCancel}>Cancel</FormButton>
          </DrawerCardActions>
        </form>
      </DrawerCardContent>
    </div>
  );
  const fileDetails = () => (
    <div style={{ width: '100%' }}>
      <div className={classes.fileCount}>{fileCount} FILES SELECTED</div>
      <div className={classes.filesContainer}>
        {files.length &&
          files.map(file => (
            <div key={file.path} className={classes.fileName}>
              {file.name}
              <ClearIcon onClick={() => removeFile(file.path)} />
            </div>
          ))}
      </div>
    </div>
  );
  const uploadForm = () => (
    <form onSubmit={handleSubmit}>
      <DrawerCardContent>
        {drawer === 'upload' ? (
          <div className={classes.uploadBox}>
            <div className={classes.uploadSection} {...getRootProps()}>
              <div>Please click or drag and drop your file</div>
              <input {...getInputProps()} />
              <CloudUploadIcon className={classes.uploadIcon} />
            </div>
            {uploadStatus ? (
              <p>
                {uploadStatus} {currentFile.name}
              </p>
            ) : null}
            {fileCount > 0 ? fileDetails() : null}
          </div>
        ) : (
          <div
            className={classes.currentFile}
            onClick={() => handleDownload(fileForm.id)}
          >
            <div>{fileForm.filename}</div>
            <DownloadIcon />
          </div>
        )}
        <FormTextField
          error
          name='filename'
          label='File Name'
          placeholder='File Name'
          value={fileForm.filename}
          onChange={handleFormChange}
        />
        <FormTextField
          error
          name='description'
          label='Description'
          placeholder='Enter transaction description'
          value={fileForm.description}
          onChange={handleFormChange}
        />
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              label='Chart of Account'
              name='accountName'
              value={fileForm.accountName}
              onChange={handleFormChange}
            >
              <FormMenuItem value=''>Select an account</FormMenuItem>
              {data.accounts.map(account => (
                <FormMenuItem key={account.id} value={account.name}>
                  {account.name}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              label='Type'
              name='type'
              value={fileForm.filetype_name}
              onChange={handleFormChange}
            >
              <FormMenuItem value=''>Select or Create Type</FormMenuItem>
              {data.types.map(type => (
                <FormMenuItem key={type.id} value={type.name}>
                  {type.name}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
          <Grid item xs={1}>
            <AddBoxOutlined
              fontSize={'large'}
              className={classes.addIcon}
              onClick={() => handleAddIcon('filetype')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems='center' justify='flex-end'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              name='tag'
              label='Tag'
              value={fileForm.filetag_name}
              onChange={handleFormChange}
            >
              <FormMenuItem value=''>Select or Create Tag</FormMenuItem>
              {data.tags.map(tag => (
                <FormMenuItem key={tag.id} value={tag.name}>
                  {tag.name}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
          <Grid item xs={1}>
            <AddBoxOutlined
              fontSize={'large'}
              className={classes.addIcon}
              onClick={() => handleAddIcon('filetag')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems='center' justify='flex-start'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              name='accessLevel'
              label='Access Level'
              value={fileForm.accessLevel}
              onChange={handleFormChange}
            >
              <FormMenuItem value='admin'>Admin</FormMenuItem>
              <FormMenuItem value='staff'>Staff</FormMenuItem>
              <FormMenuItem value='client'>Client</FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
        </Grid>
        {selectedFile ? (
          <FormControlLabel
            control={
              <Checkbox
                name='archived'
                checked={fileForm.archived}
                onChange={handleFormChange}
              />
            }
            label='Archived'
          />
        ) : null}
      </DrawerCardContent>
      <DrawerCardActions>
        <FormButton color='primary' type='submit' size={'medium'}>
          Submit
        </FormButton>
        <FormButton onClick={handleClose}>Cancel</FormButton>
      </DrawerCardActions>
    </form>
  );
  const fields = () => (
    <DrawerContent style={{ width: '100%' }}>
      <DrawerSecondaryCard
        style={{
          width: `${openCreateForm ? 380 : 0}px`,
          height: `${height}px`
        }}
      >
        {CreateForm()}
      </DrawerSecondaryCard>
      <DrawerCard style={{ height: `${height}px`, width: '400px' }}>
        <CardHeader
          title={drawer === 'upload' ? 'Upload File' : 'Edit File'}
          action={
            <DrawerCloseButton aria-label='Close' onClick={handleClose} />
          }
        />
        <div style={{ width: '100%' }}>{uploadForm()}</div>
      </DrawerCard>
    </DrawerContent>
  );
  return (
    <>
      <Drawer
        variant='temporary'
        anchor='right'
        open={openDrawer}
        onClose={handleClose}
      >
        <DrawerContent>{fields()}</DrawerContent>
      </Drawer>
    </>
  );
};
export default UploadDrawer;
